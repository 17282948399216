const firebaseConfig = {
  projectId: "grosvenor-iueyu",
  appId: "1:1049355924119:web:7d596b95a565f25b8eafd2",
  databaseURL:
    "https://grosvenor-iueyu-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "grosvenor-iueyu.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyBWwIpr0uy2hBM6VQkpCt19j_wW_wZ-OBY",
  authDomain: "grosvenor-iueyu.firebaseapp.com",
  messagingSenderId: "1049355924119",
  measurementId: "G-Z46BD1WFB4",
};

export default firebaseConfig;
